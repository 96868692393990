@font-face {
  font-family: 'Konsolev Regular';
  src: url(../../public/fonts/Konsolev-Regular.woff2) format('woff2'), url(../../public/fonts/Konsolev-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Konsolev SemiBold';
  src: url(../../public/fonts/Konsolev-Semibold.woff2) format('woff2'), url(../../public/fonts/Konsolev-Semibold.woff) format('woff');
}

@font-face {
  font-family: 'Konsolev LightItalic';
  src: url(../../public/fonts/Konsolev-LightItalic.woff2) format('woff2'), url(../../public/fonts/Konsolev-LightItalic.woff) format('woff');
}

@font-face {
  font-family: 'Bambang';
  src: url(../../public/fonts/Bambang.woff2) format('woff2'), url(../../public/fonts/Bambang.woff) format('woff');
}

@font-face {
  font-family: 'Mono Regular';
  src: url(../../public/fonts/Mono-Regular.woff2) format('woff2'), url(../../public/fonts/Mono-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Mono Bold';
  src: url(../../public/fonts/Mono-Bold.woff2) format('woff2'), url(../../public/fonts/Mono-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Mundial BoldItalic';
  src: url(../../public/fonts/Mundial-BoldItalic.woff2) format('woff2'), url(../../public/fonts/Mundial-BoldItalic.woff) format('woff');
}

:root {
  --white: #fff;
  --black: #000;
  --base: #153f45;
  --beige: #e2dfda;
  --sand: #f2f0ed;
  --pink: #ec3793;
  --cadetBlue: #aeb0c7;
  --dodgerBlue: #5351fb;
  --babyBlue: #f7fcfc;
  --juniper: #6c878b;
  --nileBlue: #153f45;
  --aurora: #00cd98;
  --sushi: #7dbc42;
  --seaSick: #bcdd9e;
  --seaFoam: #e2ecd7;
  --mustard: #f6b900;
  --vermilion: #f64000;
  --thunderbird: #c61f1f;
  --grey: #999;
  --slate: #858997;
  --gallery: #bfbfba;
  --overcast: #ada9a3;
  --haze: #edf0f0;
  --alabaster: #f5f5f5;
  --concrete: #f9f9f9;
  --pampas: #f2f0ed;
  --porcelain: #e5eaea;
  --atol: #fbfafa;
  --fsVSmall: 0.9rem;
  --fsSmall0: 1rem;
  --fsSmall1: 1.1rem;
  --fsSmall2: 1.2rem;
  --fsSmall3: 1.3rem;
  --fsSmall4: 1.4rem;
  --fsSmall5: 1.5rem;
  --fsBase: 1.6rem;
  --fsMedium7: 1.7rem;
  --fsMedium8: 1.8rem;
  --fsMedium9: 1.9rem;
  --fsLarge0: 2rem;
  --fsLarge1: 2.1rem;
  --fsLarge2: 2.2rem;
  --fsLarge3: 2.3rem;
  --fsLarge4: 2.4rem;
  --fsLarge5: 2.5rem;
  --fsLarge6: 2.6rem;
  --fsLarge8: 2.8rem;
  --fsLarge9: 2.9rem;
  --fsVLarge3: 3rem;
  --fsVLarge4: 3.2rem;
  --fsVLarge6: 3.6rem;
  --fsHuge0: 4rem;
  --fsHuge8: 4.8rem;
  --fsVHuge: 6rem;
  --fsGigantic: 8rem;
}

html {
  font-size: 62.5%;
  background-color: var(--beige);
  /* scroll-behavior: smooth; */
}

body {
  font-size: var(--fsBase);
  background-color: var(--beige);
  color: var(--base);
  padding: 0;
  margin: 0;
  font-family: 'Konsolev Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
  overflow: hidden;
}

ul,
ol {
  list-style: none;
}

ul,
ol,
h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

figcaption {
  font-size: var(--fsSmall4);
  font-style: italic;
}

* {
  box-sizing: border-box;
}

[contenteditable='true'] {
  border: 2px solid var(--sushi);
  border-radius: 10px;
  outline: 2px;
  padding: 20px;
}

/* [contenteditable],
[contenteditable] * {
  white-space: pre-wrap;
} */

.react-select [class$='control'] {
  border-radius: 10px;
  border-color: var(--grey);
}

.react-select [class$='control'] + [class$='menu'] {
  border-radius: 10px;
  background-color: var(--sand);
  padding: 10px;
  font-size: var(--fsMedium7);
}

.react-select [class$='control'] + [class$='menu'] div div {
  border-radius: 8px;
}

.react-select [class$='control'] + [class$='menu'] div div:focus-visible,
.react-select [class$='control'] + [class$='menu'] div div:active,
.react-select [class$='control'] + [class$='menu'] div div:hover {
  background-color: var(--nileBlue);
  color: var(--white);
}

.react-select [class$='control'] + [class$='menu'] div [class$='option'] {
  background-color: var(--sand);
  color: var(--nileBlue);
}

.react-select [class$='IndicatorsContainer'] {
  display: none;
}

.orderedbloglist,
.bloglist {
  list-style-type: disc;
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}

.orderedbloglist {
  list-style-type: decimal;
}

[class*='MuiSnackbarContent'] {
  font-size: var(--fsBase);
}

.MuiAccordionSummary-root [class*='MuiTypography'] {
  font-family: 'Konsolev SemiBold';
}

[hidden] {
  display: none !important;
}

.success > div > [class*='MuiSnackbarContent'] {
  background: #d8ebc6 url('/icons/icon_toast_success.svg') no-repeat 15px center;
  color: #71aa3c;
  box-shadow: none;
  border-radius: 10px;
  border: 2px solid #71aa3c;
  padding-left: 60px;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.error > div > [class*='MuiSnackbarContent'] {
  background: #f5bdbd url('/icons/icon_toast_error.svg') no-repeat 15px center;
  color: var(--thunderbird);
  box-shadow: none;
  border-radius: 10px;
  border: 2px solid var(--thunderbird);
  padding-left: 60px;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.transparent [class*='MuiSnackbarContent'] {
  background-color: transparent;
}

[class*='MuiAccordionDetails'] a {
  text-decoration: underline;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
